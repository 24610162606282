import React from "react";
import "./style.scss";

import Samespace from "../../assets/img/samespace.svg";

function Header() {
  return (
    <div className="payment-header">
      <img src={Samespace} alt="" />
    </div>
  );
}

export { Header };
