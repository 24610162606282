import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Input, Button, Radio, toast, ErrorPage, Form } from "samespace-ui-kit";
import Cookies from "universal-cookie";
import queryString from "query-string";

import { urlBase } from "utils/apiUrls";
import { toastErrors, formatCurrency, getCurrency } from "utils/common";
import { PAYMENT_OPTIONS } from "utils/constants";

import "./style.scss";

const cookies = new Cookies();

class PayComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      customer_id: props.match.params.customer_id || "",
      amount: "",
      pending: false,
      success: false,
      paymentOptions: [],
      selectedOption: {},
      paymentAmount: 0,
      loading: props.match.params.customer_id,
    };
  }

  componentDidMount() {
    this.syncState(true);
    const searchParams = new URLSearchParams(window.location.search);
    const amount = searchParams.get("amount");
    const customerId = this.props.match.params.customer_id;
    if (amount && customerId) {
      this.setState({
        paymentAmount: amount,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.customer_id !== this.props.match.params.customer_id
    ) {
      if (
        prevProps.match.params.customer_id &&
        !this.props.match.params.customer_id
      ) {
        this.reset(this.props);
      } else if (
        !prevProps.match.params.customer_id &&
        this.props.match.params.customer_id
      ) {
        this.syncState();
      }
    }
  }

  ///////////////////
  ///// request /////
  ///////////////////

  fetchPaymentOptions(load) {
    const { customer_id } = this.state;
    axios
      .get(`${urlBase}/customers/${customer_id}/payment-providers`)
      .then((res) => res.data.data)
      .then((data) => {
        if (!data.currency) {
          if (load) {
            this.setState({ notFound: load });
          } else {
            this.props.history.push("/");
          }
          throw new Error("Please enter a valid Account ID");
        }

        let minAllowedAmount = 0;
        let maxAllowedAmount = 0;
        let paymentOptions = [];
        const { currency, iso_code } = data;
        const payment_providers = data.payment_providers.filter(
          (o) => o.status === "Active"
        );
        payment_providers.forEach(({ name, ...item }) => {
          minAllowedAmount = Math.max(
            minAllowedAmount,
            item.transaction_minimum_amount
          );
          maxAllowedAmount = maxAllowedAmount
            ? Math.min(maxAllowedAmount, item.transaction_maximum_amount)
            : item.transaction_maximum_amount;
          paymentOptions.unshift({
            ...item,
            name,
            label: name === "PayPal" ? "paypal" : "card",
          });
        });
        this.setState({
          currency,
          iso_code,
          paymentOptions,
          minAllowedAmount,
          maxAllowedAmount,
          selectedOption: paymentOptions[this.state.selectedOptionIndex || 0],
          pending: false,
          nextPending: false,
          loading: false,
          step: 2,
        });
      })
      .catch((err) => {
        toast.error("Please enter a valid Account ID");
        this.setState({ nextPending: false, notFound: load });
        !load && this.props.history.push("/");
      });
  }

  ///////////////////
  ///// helpers /////
  ///////////////////

  syncState(mount) {
    const props = this.props;
    const paymentVerify = cookies.get("paymentVerify");
    const paymentAmount = cookies.get("paymentAmount");
    const selectedOptionIndex = cookies.get("selectedOptionIndex");

    const authToken = queryString.parse(props.location.search).authToken;

    const callBack = () => this.fetchPaymentOptions(mount);

    let newState = {};

    newState.paymentAmount = paymentAmount || 0;
    newState.selectedOptionIndex = selectedOptionIndex;

    if (paymentVerify && authToken) {
      if (authToken === `success${paymentVerify}`) {
        newState.transactionId = cookies.get("transactionId");
        newState.success = true;
        newState.loading = false;
      } else if (authToken === `fail${paymentVerify}`) {
        newState.error = mount && true;
        toast.error("Something went wrong while processing your request.");
      }
      props.history.push(`/${this.state.customer_id}`);
    }

    this.setState(
      {
        ...newState,
      },
      () => props.match.params.customer_id && callBack()
    );

    cookies.remove(`paymentVerify`, { path: "/" });
    cookies.remove(`paymentAmount`, { path: "/" });
    cookies.remove(`selectedOptionIndex`, { path: "/" });
  }

  reset(props) {
    this.setState(
      {
        step: 1,
        amount: "",
        nextPending: false,
        pending: false,
        success: false,
        error: false,
        selectedOption: {},

        loading: props.match.params.customer_id,
      },
      () =>
        setTimeout(
          () => this.setState({ paymentOptions: [], paymentAmount: 0 }),
          600
        )
    );
  }

  inputChangeHandler(e) {
    const { name, value } = e.target;
    if (name === "customer_id" && value.length > 10) return;
    this.setState({
      [name]: value,
    });
  }

  nextStep() {
    this.setState({ nextPending: true }, () =>
      this.props.history.push(`/${this.state.customer_id}`)
    );
  }

  disableNext() {
    return this.state.customer_id.length !== 10;
  }

  disableSubmit() {
    const {
      paymentAmount,
      selectedOption,
      minAllowedAmount,
      maxAllowedAmount,
    } = this.state;

    if (
      selectedOption.name &&
      paymentAmount > 0 &&
      paymentAmount >= minAllowedAmount &&
      paymentAmount <= maxAllowedAmount
    ) {
      return false;
    }
    return true;
  }

  submitFirstStep(e) {
    e.preventDefault();
    !this.disableNext() && this.nextStep();
  }

  submitSecondStep(e) {
    e.preventDefault();
    !this.disableSubmit() && this.makePayment();
  }

  ///////////////////////////
  ///// payment helpers /////
  ///////////////////////////

  redirect({ url }) {
    window.location = url;
  }

  post({ url, form_data }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", url);

    form.innerHTML = form_data.split("&").reduce((acc, curr) => {
      var pair = curr.split("=");
      acc += `<input type="hidden" name="${pair[0]}" value="${pair
        .splice(1)
        .join("=")}">`;
      return acc;
    }, "");

    document.body.appendChild(form);
    form.submit();
  }

  makeStripePayment({ sessionId }) {
    let stripeScript = document.createElement("script");
    stripeScript.setAttribute("id", "stripeScript");
    stripeScript.type = "text/javascript";
    stripeScript.src = "https://js.stripe.com/v3/";
    document.head.appendChild(stripeScript);

    stripeScript.onload = () => {
      const { customer_id } = this.state;
      axios
        .get(`${urlBase}/payments/stripe/public-key?customer_id=${customer_id}`)
        .then((res) => {
          const stripe = window.Stripe(res.data.data.public_key);
          stripe
            .redirectToCheckout({ sessionId })
            .then((res) => {
              this.setState({
                pending: false,
              });
              toast.error("Something went wrong.");
            })
            .catch((err) => {
              toast.error("Something went wrong.");
              this.setState({
                pending: false,
              });
            });
        })
        .catch((err) => {
          toastErrors(err);
          this.setState({
            pending: false,
          });
        });
    };
  }

  getPaymentPayload() {
    const { paymentAmount, selectedOption, customer_id, paymentOptions } =
      this.state;
    const url = `${urlBase}/payments/${
      PAYMENT_OPTIONS[selectedOption.name].route
    }`;
    const timeStampToken = new Date().getTime();
    let success_url = `${window.location.origin}/${customer_id}?authToken=success${timeStampToken}`;
    let cancel_url = `${window.location.origin}/${customer_id}?authToken=fail${timeStampToken}`;

    const selectedOptionIndex = paymentOptions.findIndex(
      ({ name }) => selectedOption.name === name
    );

    cookies.set("paymentVerify", timeStampToken, {
      path: "/",
    });
    cookies.set("paymentAmount", paymentAmount, {
      path: "/",
    });
    cookies.set("selectedOptionIndex", selectedOptionIndex, {
      path: "/",
    });

    axios({
      url,
      method: "post",
      data: {
        amount: paymentAmount,
        transaction_fee: selectedOption.transaction_fee,
        success_url,
        cancel_url,
        customer_id,
      },
    })
      .then((res) => res.data.data)
      .then((data) => {
        // save transaction_id in cookie
        cookies.set("transactionId", data.transaction_id, {
          path: "/",
        });
        switch (selectedOption.name) {
          case PAYMENT_OPTIONS.PayPal.name:
            this.redirect(data);
            break;
          case PAYMENT_OPTIONS.AuthorizeNet.name:
            this.post(data);
            break;
          case PAYMENT_OPTIONS.WorldPay.name:
            this.redirect(data);
            break;
          case PAYMENT_OPTIONS.ZaakPay.name:
            this.post(data);
            break;
          case PAYMENT_OPTIONS.Stripe.name:
            this.makeStripePayment(data);
            break;
          case PAYMENT_OPTIONS.PayU.name:
            this.post(data);
            break;
          case PAYMENT_OPTIONS.RazorPay.name:
            this.post(data);
            break;
          default:
            return;
        }
      })
      .catch((err) => {
        this.setState({
          pending: false,
        });
        toastErrors(err);
      });
  }

  makePayment() {
    this.setState(
      {
        pending: true,
      },
      () => this.getPaymentPayload()
    );
  }

  render() {
    const {
      error,
      step,
      nextPending,
      pending,
      success,
      customer_id,
      paymentOptions,
      selectedOption,
      paymentAmount,
      minAllowedAmount,
      maxAllowedAmount,
      currency,
      iso_code,
      loading,
      notFound,
      transactionId,
    } = this.state;

    const currencyInfo = { currency, iso_code };

    if (notFound) return <ErrorPage defaultAction={false} />;

    if (loading) return null;

    if (success) {
      return (
        <div className="pay-card pay-card--success pay-success fade-in">
          <img src={require("assets/img/success.png")} alt="" />
          <h5>Thank you for your payment!</h5>

          <span className="label">Reference Number</span>
          <span>{transactionId}</span>

          <span className="label">Amount</span>
          <span>{formatCurrency(paymentAmount, currencyInfo)}</span>

          <p>
            Payment receipt is sent to your account’s registered email address.
          </p>
        </div>
      );
    }

    return (
      <div className="pay-card fade-in">
        <div className={`pay-account-info ${step === 2 && "active"}`}>
          <small>Account ID</small>
          <br />
          {customer_id}
        </div>
        <div
          className={`pay ${step === 1 ? "step-1" : "step-2"} ${
            paymentOptions.length > 1 && "with-payment-options"
          }`}
        >
          <div className="pay__step pay-form__account">
            <Form onSubmit={(e) => this.submitFirstStep(e)}>
              <Input
                autoFocus
                type="number"
                name="customer_id"
                value={customer_id}
                onChange={(e) => this.inputChangeHandler(e)}
                placeholder="Account ID"
              />
              <Button
                rounded
                className="pay__sep__action"
                full
                pending={nextPending}
                disabled={this.disableNext()}
                onClick={() => this.nextStep()}
              >
                Next
              </Button>
            </Form>
          </div>
          <div className="pay__step pay-form__amount">
            <Form onSubmit={(e) => this.submitSecondStep(e)}>
              <div>
                <div>
                  <span className="amount-input">
                    <Input
                      label={getCurrency(currencyInfo)}
                      name="paymentAmount"
                      value={paymentAmount}
                      onChange={(e) => {
                        if (!isNaN(`${e.target.value}`)) {
                          if (e.target.value > maxAllowedAmount) {
                            this.inputChangeHandler({
                              target: {
                                name: "paymentAmount",
                                value: maxAllowedAmount,
                              },
                            });
                          } else {
                            this.inputChangeHandler(e);
                          }
                        }
                      }}
                      onBlur={(e) =>
                        this.inputChangeHandler({
                          target: {
                            name: "paymentAmount",
                            value: paymentAmount ? +paymentAmount : 0,
                          },
                        })
                      }
                    />
                    <span>{paymentAmount || "0"}</span>
                  </span>
                </div>

                <div className="divider" />
                <div className="pay-note">
                  <small className="text--grey">
                    Amount must be in range of&nbsp;
                    {formatCurrency(minAllowedAmount, currencyInfo)} to&nbsp;
                    {formatCurrency(maxAllowedAmount, currencyInfo)}
                  </small>
                </div>
                <div>
                  {paymentOptions.length > 1 && (
                    <div className="pay-options">
                      {paymentOptions.map((o) => {
                        return (
                          <Radio
                            name="Payment Option"
                            title={
                              o.label === "card" ? (
                                <img
                                  src={require("assets/img/card-payment.svg")}
                                  className="payment-img"
                                  alt=""
                                />
                              ) : o.label === "paypal" ? (
                                <img
                                  src={require("assets/img/paypal.png")}
                                  className="payment-img"
                                  alt=""
                                />
                              ) : (
                                o.label
                              )
                            }
                            checked={o.name === selectedOption.name}
                            onChange={() =>
                              this.setState({ selectedOption: o })
                            }
                          />
                        );
                      })}
                    </div>
                  )}

                  <Button
                    rounded
                    pending={pending}
                    full
                    disabled={this.disableSubmit()}
                    onClick={() => this.makePayment()}
                  >
                    {error ? "Try Again" : "Proceed"}
                  </Button>
                  <a
                    href="https://www.samespace.com/legal/terms/"
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    Read Terms & Conditions
                  </a>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export const Pay = withRouter(PayComponent);
