import { toast } from 'samespace-ui-kit'

export function toastErrors(err) {
  const { data = {} } = err || {}
  const { errors = [] } = data
  const message = errors.length
    ? errors.map(o => o[Object.keys(o)[0]]).join('\n')
    : 'Something went wrong!'
  toast.error(message)
}

export function formatCurrency(value, currencyInfo = {}) {
  const { currency, rounding_digit, iso_code } = currencyInfo
  if ((value || value === 0) && currency) {
    var formatter = new Intl.NumberFormat(`en-${iso_code.toUpperCase()}`, {
      style: 'currency',
      currency: currency.toUpperCase(),
      minimumFractionDigits: rounding_digit,
      maximumFractionDigits: rounding_digit,
    })
    const formattedValue = formatter.format(value)
    const formattedSections = formattedValue.split('.')
    return formattedSections[1]
      ? formattedSections[0] + '.' + formattedSections[1]
      : formattedSections[0]
  } else {
    return '–'
  }
}

export function getCurrency(currencyInfo = {}) {
  let currency
  try {
    currency = (0).toLocaleString('en-US', {
      style: 'currency',
      currency: currencyInfo.currency,
    })[0]
  } catch (err) {
    currency = ''
  }

  return currency
}
