import React from "react";
import { App as AppWrapper } from "samespace-ui-kit";
import { Switch, Router, Route } from "react-router-dom";

import { Header, Footer } from "components";
import { Pay } from "views";

import "samespace-ui-kit/static/styles.css";
import "assets/scss/style.scss";
import "assets/scss/reset.scss";

function setTitle() {
  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";

  document.title = "Payment - Samespace";
  // link.href = 'https://www.samespace.com/assets/img/favicon.png'

  document.getElementsByTagName("head")[0].appendChild(link);
}

function App() {
  setTitle();
  return (
    <>
      <AppWrapper basename={process.env.PUBLIC_URL || ""}>
        {(props) => {
          return (
            <>
              <Header />
              <div className="app-wrapper">
                <Router history={props.history}>
                  <Switch>
                    <Route path="/:customer_id?">
                      <Pay />
                    </Route>
                  </Switch>
                </Router>
              </div>
            </>
          );
        }}
      </AppWrapper>
      <Footer />
    </>
  );
}

export default App;
