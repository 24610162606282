import React from 'react'
import './style.scss'

function Footer() {
  return (
    <div className="footer">
      © Samespace {new Date().getFullYear()}. All Rights Reserved.
    </div>
  )
}

export { Footer }
