export const PAYMENT_OPTIONS = {
  PayPal: {
    name: 'PayPal',
    route: 'paypal',
  },
  AuthorizeNet: {
    name: 'AuthorizeNet',
    route: 'authorize-net',
  },
  WorldPay: {
    name: 'WorldPay',
    route: 'worldpay',
  },
  ZaakPay: {
    name: 'ZaakPay',
    route: 'zaakpay',
  },
  Stripe: {
    name: 'Stripe',
    route: 'stripe',
  },
  PayU: {
    name: 'PayU',
    route: 'payu',
  },
  RazorPay: {
    name: 'RazorPay',
    route: 'razorpay',
  },
};
